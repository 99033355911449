<script setup>
import { useForm } from '@inertiajs/vue3';
import { useDebounceFn } from '@vueuse/core';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

// Components
import Pagination from '@/Components/Pagination/PaginationFE.vue';
import Filters from '@/Components/Table/Filters.vue';
import Table from '@/Components/Table/Table.vue';

// Layouts
import Link from '@/Components/Button/Link.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';

const { t } = useI18n();
const route = inject('route');

const props = defineProps({
    query: Object,
    positions: Object,
});

const form = useForm({
    per_page: props.query.per_page ?? 25,
    page: props.query.page ?? 1,
});

const typeFilters = {};

const getData = (resetPage = true) => {
    form.page = resetPage ? 1 : form.page;

    form.get(route('staff.positions.index'), {
        preserveState: true,
        preserveScroll: true,
        only: ['positions', 'query'],
    });
};

const debouncedGetData = useDebounceFn(() => getData(), 300);

const tableSettings = [
    { label: t('Name'), width: '10%' },
    { label: '', width: '10%' },
    { label: t('CLA'), width: '10%' },
    { label: t('Sectorcode'), width: '10%' },
    { label: '', width: '5%', cell: 'Arrow' },
];

const tableData = computed(() =>
    props.positions.data.map((position) => {
        return {
            id: position.id, // used as vue key if set
            route: route('staff.positions.edit', position.id), // if set adds arrow link to row
            rowData: [
                position.name,
                position.parent_position_name,
                position.cla_name,
                position.sector_code,
                route('staff.positions.edit', position.id),
            ],
        };
    })
);

const setPage = (e) => {
    form.page = e;
    getData(false);
};
</script>

<template>
    <StaffLayout :title="$t('Positions')">
        <div class="flex flex-row items-center justify-between">
            <Filters
                :form="form"
                @anyUpdate="getData()"
                :h1="$t('Positions')"
                :data="positions"
                :typeFilters="typeFilters"
                :hasSearch="false"
            >
                <Link :href="route('staff.positions.create', { type: 'main' })" :text="$t('Main position')" />
                <Link :href="route('staff.positions.create', { type: 'secondary' })" :text="$t('Position')" />
            </Filters>
        </div>

        <template v-if="typeof positions !== 'undefined' && positions.data.length > 0">
            <Table
                class="mb-8"
                :loading="form.processing"
                :tableSettings="tableSettings"
                :data="tableData"
                :footerFaded="query.search != '' && query.search != null"
                v-model:orderBy="form.orderBy"
                @update:orderBy="getData()"
                v-model:orderDir="form.orderDir"
                @update:orderDir="getData()"
            />

            <Pagination
                v-if="typeof positions !== 'undefined'"
                :links="positions.links"
                @setPage="setPage"
                :pageData="positions"
                :hideTotal="query.search != '' && query.search != null"
            />
        </template>
        <template v-else>
            <div class="w-full px-4 py-3 overflow-hidden font-bold leading-tight bg-white rounded">
                {{ $t('No {model} found', { model: $t('positions') }) }}
            </div>
        </template>
    </StaffLayout>
</template>
